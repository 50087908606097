export const DataAnalysis = () => {
    return (
        <svg width="48" height="48" viewBox="0 10 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.3337 9.33334L10.667 16.667V31.3331L23.3337 38.6667L36.0003 31.3331V16.667L23.3337 9.33334ZM12.3723 17.6553L23.3337 11.309L34.2951 17.6553V30.347L23.3337 36.6945L12.3723 30.347V17.6553Z"
                fill="#008FC1"
            />
            <path
                d="M28.662 27.1958L33.7698 18.289L23.2336 12.947L15.1143 19.315V28.4418L23.5269 33.3803L28.662 27.1958ZM16.8195 27.466V20.1518L23.4325 14.9636L31.3961 19.0015L27.2921 26.1835L23.1392 31.171L16.8195 27.466Z"
                fill="#008FC1"
            />
        </svg>
    );
};
