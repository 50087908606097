import { FC } from "react";

const Mail: FC = () => {
  return (
    <svg
      width='26'
      height='16'
      viewBox='0 0 26 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25.3876 0H0.594777C0.271302 0 0 0.263052 0 0.599759V14.8361C0 15.2676 0.354779 15.6253 0.782601 15.6253H25.1998C25.6276 15.6253 25.9824 15.2676 25.9824 14.8361V0.599759C25.9824 0.273574 25.7215 0 25.3876 0ZM22.6954 1.57831L12.9912 9.10161L3.28693 1.57831H22.6954ZM24.4172 13.121L18.939 8.3861L17.9164 9.58562L23.0815 14.047H2.90084L8.06601 9.58562L7.04341 8.3861L1.5652 13.121V2.23068L12.5112 10.722C12.7929 10.9325 13.179 10.9325 13.4607 10.722L24.4172 2.23068V13.121Z'
        fill='white'
      />
    </svg>
  );
};

export default Mail;
