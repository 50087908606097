import { FC } from 'react';
import classes from './OffersBanner.module.scss';
import { CircleArrow } from 'components/icons';
import { useServicesStore } from 'store/services.store';
import { useTranslation } from 'react-i18next';

const OffersBanner: FC = () => {
    const { setIsOffersVisible, setActiveSection } = useServicesStore();
    const { t } = useTranslation('offersBanner');
    return (
        <div className={classes.container}>
            <h3>{t('title')}</h3>
            <span>{t('subtitle')}</span>
            <a
                onClick={() => {
                    setIsOffersVisible(true);
                    setActiveSection('#offers');
                }}
                href="#offers"
            >
                <b>{t('learnMore')}</b>
                <CircleArrow />
            </a>
        </div>
    );
};

export default OffersBanner;
