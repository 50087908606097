import Navlinks from 'components/navbar/navlinks/Navlinks';
import classes from './Navbar.module.scss';
import LangMenu from 'components/langMenu/LangMenu';
import { Close, Hamburger } from 'components/icons';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { availableLanguages } from 'i18n';

const Navbar: FC = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
    const ref = useRef<any>();
    const { t, i18n } = useTranslation('translation');
    const onChangeLanguage = useCallback((language: string) => i18n.changeLanguage(language), [i18n]);
    const navigate = useNavigate();
    const path = document.location.pathname.concat('');
    const loc = path.slice(0, -2);

    useEffect(() => {
        if (!i18n) return;
        const path = document.location.pathname.concat('');
        const split = path.split('/');
        const location = split[split.length - 1];
        setTimeout(() => {
            i18n.changeLanguage(location);
        }, 10);
    }, [i18n]);

    useEffect(() => {
        const path = document.location.pathname.concat('');
        const split = path.split('/');
        const location = split[split.length - 1];
        const lastSlashIndex = path.lastIndexOf('/');
        const locDef = path.substring(0, lastSlashIndex + 1);
        if (!availableLanguages.includes(location)) {
            setTimeout(() => {
                navigate(`${locDef}en`);
            }, 10);
        }
    }, [navigate]);

    useEffect(() => {
        const handleOutsideClick = (event: any) => {
            const excludedDiv = document.getElementById('mobile-container');
            if (ref.current && !ref.current.contains(event.target) && (!excludedDiv || !excludedDiv.contains(event.target)))
                return setIsSidebarOpen(false);
        };
        document.addEventListener('mousedown', handleOutsideClick);
        document.addEventListener('touchstart', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('touchstart', handleOutsideClick);
        };
    }, []);

    return (
        <>
            <div className={classes.container}>
                <div className={classes.logo}></div>
                <div className={classes.linksContainer}>
                    <div className={classes.desktopLinks}>
                        <Navlinks setIsSidebarOpen={setIsSidebarOpen} />
                    </div>
                    <LangMenu />
                    <div onClick={() => setIsSidebarOpen(prev => !prev)} className={classes.hamburger} ref={ref}>
                        {isSidebarOpen ? <Close /> : <Hamburger />}
                    </div>
                </div>
            </div>
            <div id="mobile-container">
                <div className={` ${classes.sidebar} ${!isSidebarOpen ? classes.closedSidebar : classes.openSidebar} `}>
                    <Navlinks setIsSidebarOpen={setIsSidebarOpen} />
                    <div className={classes.lang}>
                        <div>
                            <img src="assets/ita.png" alt="flag_ita" />
                            <input
                                type="radio"
                                id="ita"
                                name="lang"
                                value="it"
                                onClick={() => {
                                    onChangeLanguage('it');
                                    navigate(`${loc}it`);
                                }}
                            />
                            <label htmlFor="ita" className={i18n.language.includes('it') ? classes.selected : ''}>
                                {t('it')}
                            </label>
                        </div>

                        <div>
                            <img src="assets/eng.png" alt="flag_eng" />
                            <input
                                type="radio"
                                id="eng"
                                name="lang"
                                value="en"
                                onClick={() => {
                                    onChangeLanguage('en');
                                    navigate(`${loc}en`);
                                }}
                            />
                            <label htmlFor="eng" className={i18n.language.includes('en') ? classes.selected : ''}>
                                {t('en')}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Navbar;
