import { FC, useMemo } from 'react';
import classes from './OfferCard.module.scss';
import Chip from 'components/chip/Chip';

type OfferCardProps = FC<{
    img: string;
    title: string;
    subtitle: string;
    paragraph: string;
    isGreen?: boolean;
    chips?: string[];
}>;

const OfferCard: OfferCardProps = ({ img, title, subtitle, paragraph, isGreen, chips }) => {
    const offerChips = useMemo(() => {
        return chips?.map(chip => <Chip key={chip} title={chip} />);
    }, [chips]);

    return (
        <div className={`${classes.container} ${isGreen && classes.green}`}>
            <img alt={img} src={img} />
            <div className={classes.content}>
                <span className={`sub ${isGreen ? classes.white : classes.dark}`}>{title}</span>
                <p className={isGreen ? classes.white : classes.subtitle}>{subtitle}</p>
                <div>{paragraph}</div>
                <div className={classes.chips}>{offerChips}</div>
            </div>
        </div>
    );
};

export default OfferCard;
