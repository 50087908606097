import { FC } from 'react';
import classes from './Revolution.module.scss';
import { useTranslation } from 'react-i18next';


const Revolution: FC = () => {
    const {t} = useTranslation('revolution')
    
    return (
        <div className={classes.revolution}>
            <h2>{t("title")}</h2>
            <p>
                {t("firstPar1")}
                <b>{t("firstParHighlight")}</b>
                {t("firstPar2")}
            </p>
            <p>
                {t("secondPar1")}
                <b>{t("secondParHighlight")}</b>
                {t("secondPar2")}
            </p>
        </div>
    );
};

export default Revolution;
