import Homepage from 'views/home/homepage/Homepage';
import classes from './Home.module.scss';
import Footer from 'components/footer/Footer';
import { FC, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import UseSection from './useSection/UseSection';
import Revolution from './revolution/Revolution';
import Assets from './assets/Assets';
import OurPlus from './ourPlus/OurPlus';
import OurKit from './ourKit/OurKit';
import OffersBanner from './offersBanner/OffersBanner';
import Offers from './offers/Offers';
import { useServicesStore } from 'store/services.store';
import CerBanner from 'views/home/cerBanner/CerBanner';
import Privacy from 'views/home/privacy/Privacy';
import ContactUs from './contactUs/ContactUs';

const Home: FC = () => {
    const { setActiveSection } = useServicesStore();
    const [refHome, inViewHome] = useInView();

    useEffect(() => {
        if (inViewHome) setActiveSection('#homePage');
    }, [inViewHome]);

    return (
        <div className={classes.container}>
            <section ref={refHome} id="homePage">
                <Homepage />
                <Revolution />
                <UseSection />
                <OurPlus />
                <Assets />
                <OurKit />
                <OffersBanner />
                <CerBanner />
                <Footer />
            </section>
            <section>
                <Offers />
            </section>
            <section>
                <Privacy />
            </section>
            <section>
                <ContactUs />
            </section>
        </div>
    );
};

export default Home;
