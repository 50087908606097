import { FC, useMemo } from 'react';
import classes from './UseSection.module.scss';
import UseSectionCard from 'components/useSectionCard/UseSectionCard';
import Community from 'views/home/useSection/community/Community';
import Industry from 'views/home/useSection/industry/Industry';
import { useServicesStore } from 'store/services.store';
import { useTranslation } from 'react-i18next';

const UseSection: FC = () => {
    const { setIsCommunityVisible, setIsIndustryVisible } = useServicesStore();
    const { t } = useTranslation('mission');
    const SECTIONS = useMemo(() => {
        return [
            {
                image: 'assets/Card_1.png',
                content: (
                    <>
                        {t('card1.body1')}
                        <b>{t('card1.bodyBold')}</b>
                        {t('card1.body2')}
                    </>
                ),
                title: `${t('card1.title')}`,
                onClick: () => setIsIndustryVisible(true)
            },
            {
                image: 'assets/Card_2.png',
                content: (
                    <>
                        {t('card2.body1')}
                        <b>{t('card2.bodyBold')}</b> {t('card2.body2')}
                    </>
                ),
                title: `${t('card2.title')}`,
                onClick: () => setIsCommunityVisible(true)
            }
        ];
    }, [t]);

    return (
        <>
            <div className={classes.container}>
                <h2>{t('title')}</h2>
                <div className={classes.cards}>
                    {SECTIONS.map(section => (
                        <UseSectionCard
                            key={section.title}
                            onClick={section.onClick}
                            image={section.image}
                            title={section.title}
                            content={section.content}
                        />
                    ))}
                </div>
            </div>
            <Community />
            <Industry />
        </>
    );
};

export default UseSection;
