import { FC } from 'react';
import { HashLink } from 'react-router-hash-link';
import classes from './Navlinks.module.scss';
import { SetState } from 'helpers/interfaces';
import { useTranslation } from 'react-i18next';
import { useServicesStore } from 'store/services.store';

type NavLinksProps = FC<{
    setIsSidebarOpen: SetState<boolean>;
}>;

const Navlinks: NavLinksProps = ({ setIsSidebarOpen }) => {
    const {
        activeSection,
        setIsCommunityVisible,
        setIsIndustryVisible,
        setIsOffersVisible,
        setActiveSection,
        setIsContactVisible
    } = useServicesStore();
    const { t } = useTranslation('navbar');

    const handleClick = () => {
        setIsSidebarOpen(false);
        setIsCommunityVisible(false);
        setIsIndustryVisible(false);
        setIsOffersVisible(false);
        setIsContactVisible(false);
        setActiveSection('#homePage');
    };

    return (
        <>
            <div className={`${classes.link}  ${activeSection === '#homePage' && classes.active} `}>
                <HashLink onClick={handleClick} smooth to="#homePage">
                    <span className="sub">HOME</span>
                </HashLink>
            </div>
            <div className={`${classes.link}  ${activeSection === '#offers' && classes.active}  `}>
                <HashLink
                    onClick={() => {
                        setIsOffersVisible(true);
                        setActiveSection('#offers');
                        setIsContactVisible(false);
                    }}
                    to="#offers"
                >
                    <span className="sub">{t('offers')}</span>
                </HashLink>
            </div>
            <div className={`${classes.link}  ${activeSection === '#contactUs' && classes.active}  `}>
                <HashLink
                    onClick={() => {
                        setIsOffersVisible(false);
                        setIsContactVisible(true);
                        setActiveSection('#contactUs');
                    }}
                    to="#contactUs"
                >
                    <span className="sub">{t('contact')}</span>
                </HashLink>
            </div>
        </>
    );
};

export default Navlinks;
