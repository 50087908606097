import { FC } from 'react';
import classes from './Footer.module.scss';
import Mail from 'components/icons/Mail';
import { useTranslation } from 'react-i18next';
import { useServicesStore } from 'store/services.store';

const Footer: FC = () => {
    const { setIsPrivacyVisible } = useServicesStore();
    const { t } = useTranslation('footer');

    return (
        <>
            <div className={classes.topContainer}>
                <h3>{t('title')}</h3>
                <p>
                    <b>{t('subtitle')}</b>
                </p>
                <a href="mailto:info@eagleflow.it">
                    <Mail /> <b>info@eagleflow.it</b>
                </a>
            </div>
            <div className={classes.bottomContainer}>
                <div className={classes.webContainer}>
                    <span>
                        {t('info')}&nbsp;
                        <br />
                        <b className="primary300">EagleProjects S.p.A.</b>
                    </span>
                    <a target="_blank" rel="noreferrer" href={'https://www.eagleprojects.it'}>
                        <b>{t('callToAction')}</b>
                    </a>
                </div>
                <div className={classes.contactsContainer}>
                    <span>{t('quarter')}</span>
                    <b>Strada San Galigano, 12/A - 06125 PG</b>
                    <span>C.F./P.IVA</span>
                    <b>03489760540</b>
                </div>
                <div className={classes.contactsContainer}>
                    <span>Email</span>
                    <b>
                        eagleprojects@pec.it
                        <br />
                        info@eagleflow.it
                    </b>
                    <span>{t('phone')}</span>
                    <b>075 7750322</b>
                </div>
                <div className={classes.privacy}>
                    <a onClick={() => setIsPrivacyVisible(true)} href="#privacy">
                        <b>Privacy Policy </b>
                    </a>
                </div>
                <img src="/assets/logo_eagle_footer.png" alt="logo" />
            </div>
        </>
    );
};

export default Footer;
