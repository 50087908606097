import Navbar from 'components/navbar/Navbar';
import './styles/main.scss';
import Home from 'views/home/Home';

function App() {
    return (
        <>
            <Navbar />
            <Home />
        </>
    );
}

export default App;
