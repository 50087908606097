export const Winner = () => {
    return (
        <svg width="48" height="48" viewBox="0 10 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M28.87 23.451C32.9313 23.2558 36 17.5225 36 14.6176C36 14.4538 35.9357 14.2967 35.8213 14.1809C35.7069 14.0651 35.5517 14 35.3898 14H12.6102C12.4483 14 12.2931 14.0651 12.1787 14.1809C12.0643 14.2967 12 14.4538 12 14.6176C12 17.5192 15.068 23.2558 19.1301 23.451C20.23 25.8948 21.6854 27.9286 23.3898 28.3305V30.4713H20.7458C20.6324 30.4712 20.5212 30.5031 20.4248 30.5635C20.3283 30.6239 20.2505 30.7103 20.1999 30.813L18.5728 34.1066C18.5263 34.2008 18.5044 34.3054 18.5091 34.4105C18.5138 34.5156 18.5451 34.6178 18.5997 34.7073C18.6544 34.7968 18.7308 34.8706 18.8216 34.9219C18.9124 34.9731 19.0147 35 19.1186 35H28.8814C28.9853 35 29.0876 34.9731 29.1784 34.9219C29.2692 34.8706 29.3456 34.7968 29.4003 34.7073C29.455 34.6178 29.4862 34.5156 29.4909 34.4105C29.4956 34.3054 29.4737 34.2008 29.4273 34.1066L27.8002 30.813C27.7496 30.7103 27.6717 30.6239 27.5753 30.5635C27.4788 30.5031 27.3676 30.4712 27.2542 30.4713H24.6102V28.3305C26.3186 27.9286 27.7701 25.8948 28.87 23.451ZM27.8937 33.7616H20.1063L21.1232 31.7031H26.8768L27.8937 33.7616ZM29.4151 22.1352C30.2738 19.9205 30.8379 17.6002 31.0926 15.2351H34.7341C34.3794 17.6551 32.1307 21.5036 29.4183 22.1352M13.2724 15.2351H16.9107C17.165 17.6003 17.7291 19.9206 18.5882 22.1352C15.8799 21.5036 13.6255 17.6551 13.2724 15.2351ZM18.1294 15.2351H29.8772C29.5957 19.3183 26.7336 27.1744 24.0033 27.1744C21.273 27.1744 18.41 19.3183 18.1294 15.2351Z"
                fill="#008FC1"
            />
        </svg>
    );
};
