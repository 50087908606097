import { FC } from 'react';
import classes from './UseSectionCard.module.scss';
import { CircleArrow } from 'components/icons';
import { useTranslation } from 'react-i18next';

type UseSectionCardProps = FC<{
    title: string;
    content: JSX.Element;
    image: string;
    onClick: () => void;
}>;

const UseSectionCard: UseSectionCardProps = ({ title, content, image, onClick }) => {
    const {t} = useTranslation("mission")
    
    return (
        <div className={classes.cardContainer} style={{ backgroundImage: `url(${image})` }}>
            <div>
                <h3>{title}</h3>
                <div className={classes.content}>
                    <b>{content}</b>
                </div>
            </div>
            <div onClick={onClick} className={classes.button}>
                <b>{t("action")}</b>
                <CircleArrow />
            </div>
        </div>
    );
};

export default UseSectionCard;
