export const Unplug = () => {
    return (
        <svg width="48" height="48" viewBox="0 10 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M38.6661 10.5716L37.4212 9.33337L35.0191 11.734C33.7354 10.7339 32.1295 10.2385 30.5051 10.3415C28.8807 10.4445 27.3504 11.1387 26.2034 12.2929L22.6872 15.8057L21.2859 14.4053L20.0469 15.6435L32.3495 27.9312L33.5874 26.693L32.186 25.2925L35.701 21.7798C36.8561 20.6334 37.5508 19.1037 37.6537 17.4801C37.7565 15.8565 37.2604 14.2515 36.2592 12.9687L38.6661 10.5716ZM34.462 20.5451L30.9528 24.052L23.9344 17.0381L27.4378 13.53C28.3686 12.5999 29.6311 12.0775 30.9474 12.0776C32.2637 12.0777 33.5261 12.6004 34.4568 13.5306C35.3875 14.4609 35.9103 15.7224 35.9102 17.0379C35.91 18.3534 35.387 19.615 34.4562 20.5451H34.462Z"
                fill="#008FC1"
            />
            <path
                d="M24.8716 29.3086L27.213 26.9675L25.9751 25.7305L23.6396 28.0715L19.9225 24.3569L22.2651 22.017L21.0272 20.7799L18.6846 23.1198L17.6523 22.0881L15.6344 20.0715L14.3965 21.3097L15.7979 22.7102L12.2945 26.2183C11.1397 27.3646 10.4453 28.8941 10.3424 30.5174C10.2396 32.1408 10.7354 33.7455 11.7363 35.0282L9.33301 37.4288L10.572 38.667L12.9742 36.2653C14.2577 37.2655 15.8635 37.7611 17.4879 37.6583C19.1123 37.5555 20.6427 36.8615 21.7898 35.7075L25.3002 32.2005L26.7015 33.601L27.9405 32.3639L25.9226 30.3472L24.8716 29.3086ZM20.5368 34.4657C20.0779 34.9351 19.5304 35.3088 18.926 35.5652C18.3215 35.8216 17.6722 35.9556 17.0156 35.9594C16.359 35.9632 15.7081 35.8369 15.1007 35.5875C14.4934 35.3382 13.9416 34.9708 13.4772 34.5068C13.0129 34.0428 12.6454 33.4913 12.3959 32.8843C12.1464 32.2773 12.0199 31.6269 12.0237 30.9707C12.0275 30.3145 12.1616 29.6657 12.4181 29.0616C12.6747 28.4576 13.0487 27.9104 13.5184 27.4518L17.0276 23.9449L24.0459 30.9588L20.5368 34.4657Z"
                fill="#008FC1"
            />
        </svg>
    );
};
