import { FC } from 'react';
import classes from './Assets.module.scss';
import { useTranslation } from 'react-i18next';

const Assets: FC = () => {
    const {t} = useTranslation("functionality")
    
    return (
        <div className={classes.asset}>
            <h2>{t("title")}</h2>
            <div className={classes.assetGrid}>
                <div>
                    <img src="assets/asset_1.png" alt="asset_1" />
                    <div>
                        <h3>{t("sensor.title")}</h3>{t("sensor.body1")}<b>{t("sensor.bodyHighlight")}</b> {t("sensor.body2")} <br /> <br /> {t("sensor.body3")}
                    </div>
                </div>
                <div>
                    <img src="assets/asset_2.png" alt="asset_2" />
                    <div>
                        <h3>{t("cost.title")}</h3>
                        {t("cost.body1")}
                        <br />
                        <br />
                        {t("cost.body2")}
                        <b>{t("cost.bodyHighlight")}</b> {t("cost.body3")}
                    </div>
                </div>
                <div>
                    <img src="assets/asset_3.png" alt="asset_3" />
                    <div>
                        <h3>{t("historical.title")}</h3>
                        {t("historical.body1")}
                        <br />
                        <br />
                        {t("historical.body2")}
                        <b>{t("historical.bodyHighlight")}</b>
                        {t("historical.body3")}
                    </div>
                </div>
                <div>
                    <img src="assets/asset_4.png" alt="asset_4" />
                    <div>
                        <h3>{t("pricing.title")}</h3>
                        {t("pricing.body1")}
                        <b>{t("pricing.bodyHighlight")}</b>
                        {t("pricing.body2")}
                        <br />
                        <br />
                        {t("pricing.body3")}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Assets;
