import { FC, useMemo } from 'react';
import classes from './OurKit.module.scss';
import KitCard from 'components/kitCard/KitCard';
import { useTranslation } from 'react-i18next';

const OurKit: FC = () => {
    const { t } = useTranslation('kit');
    const KIT_CARDS = useMemo(
        () => [
            {
                img: 'assets/kit_1.png',
                title: `${t('kit1.title')}`,
                subTitle: `${t('kit1.subtitle')}`,
                paragraph: `${t('kit1.body')}`
            },
            {
                img: 'assets/kit_2.png',
                title: `${t('kit2.title')}`,
                subTitle: `${t('kit2.subtitle')}`,
                paragraph: `${t('kit2.body')}`
            },
            {
                img: 'assets/kit_3.png',
                title: `${t('kit3.title')}`,
                subTitle: `${t('kit3.subtitle')}`,
                paragraph: `${t('kit3.body')}`
            }
        ],
        [t]
    );

    return (
        <div className={classes.container}>
            <h2>{t('mainTitle')}</h2>
            <div className={classes.paragraph}>
                <span>{t('subtitle1')}</span>
                <span>{t('subtitle2')}</span>
            </div>
            <div className={classes.cards}>
                {KIT_CARDS.map(card => (
                    <KitCard img={card.img} title={card.title} subTitle={card.subTitle} paragraph={card.paragraph} />
                ))}
            </div>
        </div>
    );
};

export default OurKit;
