export const Graph = () => {
    return (
        <svg width="48" height="48" viewBox="0 10 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.267 10.6666H10.667V37.3333H37.3337V35.7333H12.267V10.6666Z" fill="#008FC1" />
            <path
                d="M33.0673 16.1558V17.7558H34.6235L28.0891 24.2902L24.2673 20.4683L13.9131 30.8224L15.0449 31.9542L24.2673 22.7318L28.0891 26.5536L35.7339 18.9088V20.4235H37.3339V16.1568L33.0673 16.1558Z"
                fill="#008FC1"
            />
        </svg>
    );
};
