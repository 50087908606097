export const Close = () => {
    return (
        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                id="Close"
                d="M0.28503 14.5697L1.72341 16L9.71533 8L1.71533 -6.99382e-07L0.285031 1.4303L6.85473 8L0.28503 14.5697Z"
                fill="#fff"
            />
        </svg>
    );
};
