import { create } from 'zustand';

export interface ServicesState {
    activeSection: string;
    isCommunityVisible: boolean;
    isIndustryVisible: boolean;
    isOffersVisible: boolean;
    isPrivacyVisible: boolean;
    isContactVisible: boolean;
    setActiveSection: (section: string) => void;
    setIsPrivacyVisible: (isVisible: boolean) => void;
    setIsCommunityVisible: (isVisible: boolean) => void;
    setIsIndustryVisible: (isVisible: boolean) => void;
    setIsOffersVisible: (isVisible: boolean) => void;
    setIsContactVisible: (isVisible: boolean) => void;
}

export const useServicesStore = create<ServicesState>(set => ({
    activeSection: '#homePage',
    isCommunityVisible: false,
    isIndustryVisible: false,
    isOffersVisible: false,
    isPrivacyVisible: false,
    isContactVisible: false,
    setActiveSection: section => set({ activeSection: section }),
    setIsCommunityVisible: isVisible => set({ isCommunityVisible: isVisible }),
    setIsIndustryVisible: isVisible => set({ isIndustryVisible: isVisible }),
    setIsOffersVisible: isVisible => set({ isOffersVisible: isVisible }),
    setIsPrivacyVisible: isVisible => set({ isPrivacyVisible: isVisible }),
    setIsContactVisible: isVisible => set({ isContactVisible: isVisible })
}));
