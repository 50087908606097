export const Dollar = () => {
    return (
        <svg width="48" height="48" viewBox="0 10 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24.5037 23.4C23.3691 23.4 22.281 22.9575 21.4788 22.1698C20.6765 21.3822 20.2258 20.3139 20.2258 19.2C20.2258 18.0861 20.6765 17.0178 21.4788 16.2302C22.281 15.4425 23.3691 15 24.5037 15H28.7815V13.8H25.1148V11H23.8925V13.8376C22.495 13.9896 21.2098 14.6615 20.3014 15.7153C19.3929 16.769 18.9303 18.1245 19.0085 19.5029C19.0868 20.8813 19.7001 22.1778 20.7222 23.1259C21.7443 24.074 23.0975 24.6015 24.5037 24.6C25.6382 24.6 26.7263 25.0425 27.5286 25.8302C28.3308 26.6178 28.7815 27.6861 28.7815 28.8C28.7815 29.9139 28.3308 30.9822 27.5286 31.7698C26.7263 32.5575 25.6382 33 24.5037 33H20.2258V34.2H23.8925V37H25.1148V34.1616C26.5109 34.0079 27.7941 33.3354 28.701 32.282C29.6079 31.2286 30.0697 29.8743 29.9915 28.4971C29.9133 27.12 29.301 25.8245 28.2805 24.8766C27.2599 23.9287 25.9085 23.4003 24.5037 23.4Z"
                fill="#008FC1"
            />
        </svg>
    );
};
