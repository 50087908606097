export const CircleArrow = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" viewBox="0 0 49 48" fill="transparent">
            <circle cx="24.7139" cy="24" r="23" stroke="white" strokeWidth="2" />
            <path
                d="M11.7139 23C11.1616 23 10.7139 23.4477 10.7139 24C10.7139 24.5523 11.1616 25 11.7139 25V23ZM38.421 24.7071C38.8115 24.3166 38.8115 23.6834 38.421 23.2929L32.057 16.9289C31.6665 16.5384 31.0333 16.5384 30.6428 16.9289C30.2523 17.3195 30.2523 17.9526 30.6428 18.3431L36.2997 24L30.6428 29.6569C30.2523 30.0474 30.2523 30.6805 30.6428 31.0711C31.0333 31.4616 31.6665 31.4616 32.057 31.0711L38.421 24.7071ZM11.7139 25H37.7139V23H11.7139V25Z"
                fill="white"
            />
        </svg>
    );
};
