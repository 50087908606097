export const IotPlant = () => {
    return (
        <svg width="48" height="48" viewBox="0 10 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M30.1572 23.6399C29.2781 23.6416 28.409 23.8262 27.6054 24.1818C26.8019 24.5375 26.0816 25.0565 25.4905 25.7056V22.5447C25.4888 20.8438 24.8107 19.213 23.605 18.0102C22.3993 16.8074 20.7645 16.1307 19.0593 16.1287H10.667V18.9851C10.669 20.6547 11.3349 22.2554 12.5185 23.436C13.7021 24.6167 15.3068 25.2808 16.9807 25.2828H23.8435V35.9999H25.4905V32.793H32.3533C34.0271 32.791 35.6319 32.1268 36.8155 30.9462C37.9991 29.7656 38.665 28.1649 38.667 26.4953V23.6399H30.1572ZM37.0199 25.2828V26.4953C37.0185 27.7294 36.5264 28.9125 35.6515 29.7852C34.7767 30.6578 33.5905 31.1487 32.3533 31.1501H25.4905V29.9377C25.492 28.7036 25.9841 27.5205 26.859 26.6478C27.7338 25.7752 28.92 25.2843 30.1572 25.2828H37.0199ZM23.8435 22.5447V23.6399H16.9807C15.7435 23.6385 14.5574 23.1476 13.6825 22.275C12.8077 21.4023 12.3155 20.2192 12.3141 18.9851V17.7715H19.0582C20.3269 17.773 21.5432 18.2764 22.4403 19.1712C23.3374 20.066 23.842 21.2792 23.8435 22.5447Z"
                fill="#008FC1"
            />
            <path
                d="M26.5629 21.8984L26.5805 22.1525H28.2276L28.2089 21.8622C28.0691 19.6688 27.1326 17.6015 25.5746 16.0473C24.0167 14.4931 21.9441 13.5586 19.7452 13.4189L19.4531 13.4003V15.0432L19.7079 15.0618C21.4813 15.1953 23.1481 15.9584 24.4058 17.2127C25.6634 18.467 26.4288 20.1294 26.5629 21.8984Z"
                fill="#008FC1"
            />
            <path
                d="M29.3069 21.8941L29.3212 22.1526H30.9683L30.9551 21.8656C30.8266 18.9402 29.6039 16.169 27.5279 14.0986C25.452 12.0281 22.6736 10.8087 19.7408 10.6809L19.4531 10.6666V12.3095L19.7134 12.3227C22.2171 12.4492 24.5845 13.4983 26.357 15.2667C28.1294 17.035 29.1806 19.3967 29.3069 21.8941Z"
                fill="#008FC1"
            />
        </svg>
    );
};
