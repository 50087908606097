import { FC } from 'react';
import classes from './PlusCard.module.scss';

type PlusCardProps = FC<{
    svg: JSX.Element;
    title: string;
    paragraph: string;
}>;

const PlusCard: PlusCardProps = ({ paragraph, svg, title }) => {
    return (
        <div className={classes.container}>
            <div>{svg}</div>
            <div className={classes.text}>
                <span className="sub">{title}</span>
                {paragraph}
            </div>
        </div>
    );
};

export default PlusCard;
