import axios, { AxiosInstance } from 'axios';
import { UseFormReset } from 'react-hook-form';
import { Message, resetForm } from 'views/home/contactUs/ContactUs';

class Service {
    api: AxiosInstance;

    constructor() {
        this.api = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL
        });
    }
    createApplication = async (data: Message, reset: UseFormReset<Message>, handleSuccessfulMessage: any) => {
        try {
            delete data.isPrivacyChecked;
            const response = await this.api.post('/contact', data.message);
            resetForm(reset, handleSuccessfulMessage);
            return response;
        } catch (error) {
            console.log(error);
        }
    };
}

const service = new Service();

export default service;
