import classes from '../UseSectionPage.module.scss';
import { FC } from 'react';
import { ArrowBackForward, ArrowBackForwardSmall, Graph, IotPlant, Unplug } from 'components/icons';
import Footer from 'components/footer/Footer';
import { useServicesStore } from 'store/services.store';
import { useTranslation } from 'react-i18next';

const Industry: FC = () => {
    const { t } = useTranslation('industry');
    const { isIndustryVisible, setIsIndustryVisible } = useServicesStore();
    const desktopWidth = window.innerWidth > 1400;
    return (
        <div className={`${classes.container} ${isIndustryVisible ? classes.openContainer : classes.closedContainer}`}>
            <div className={classes.title}>
                <div onClick={() => setIsIndustryVisible(false)} className={classes.arrow}>
                    {desktopWidth ? <ArrowBackForward /> : <ArrowBackForwardSmall />}
                </div>
                <h3>{t('title')}</h3>
            </div>
            <div className={classes.content}>
                <img alt="Industry_Image" src="assets/Industry.png" />
                <div className={classes.contentBody}>
                    <p>
                        {t('paragraph_1')}&nbsp;
                        <b>{t('paragraph_1_highlight')}</b>
                        {t('paragraph_1_1')}
                        <b>{t('paragraph_1_1_highlight')}</b>.
                    </p>
                    <p>{t('paragraph_2')}</p>
                    <div className={classes.applications}>
                        <div className={classes.application}>
                            <div>
                                <Unplug />
                            </div>
                            <div>
                                <span className="sub">{t('optimization')}</span>
                                <div className={classes.paragraph}>{t('optimization_paragraph')}</div>
                            </div>
                        </div>
                        <div className={classes.application}>
                            <div>
                                <IotPlant />
                            </div>
                            <div>
                                <span className="sub">{t('energy')}</span>
                                <div className={classes.paragraph}>{t('energy_paragraph')}</div>
                            </div>
                        </div>
                        <div className={classes.application}>
                            <div>
                                <Graph />
                            </div>
                            <div>
                                <span className="sub">{t('strategy')}</span>
                                <div className={classes.paragraph}>{t('strategy_paragraph')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Industry;
