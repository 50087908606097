import { Dollar, Easy, Electrical } from 'components/icons';
import PlusCard from 'components/plusCard/PlusCard';
import { FC, useMemo } from 'react';
import classes from './OurPlus.module.scss';
import { useTranslation } from 'react-i18next';

const OurPlus: FC = () => {
    const {t} = useTranslation("plus")
    const PlusCards = useMemo(()=>[
        {
            paragraph:
            `${t("economy.body")}`,
            title: `${t("economy.title")}`,
            svg: <Dollar />
        },
        {
            paragraph: `${t("energyMonitoring.body")}`,
            title: `${t("energyMonitoring.title")}`,
            svg: <Electrical />
        },
        {
            paragraph: `${t("monitoringPoint.body")}`,
            title: `${t("monitoringPoint.title")}`,
            svg: <Easy />
        }
    ],[t]);
    return (
        <div className={classes.container}>
            <h2>I nostri plus</h2>
            <div className={classes.cards}>
                {PlusCards.map(card => (
                    <PlusCard key={card.title} paragraph={card.paragraph} svg={card.svg} title={card.title} />
                ))}
            </div>
        </div>
    );
};

export default OurPlus;
