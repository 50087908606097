import { FC } from 'react';
import classes from '../UseSectionPage.module.scss';
import { ArrowBackForward, ArrowBackForwardSmall, DataAnalysis, Split, Winner } from 'components/icons';
import Footer from 'components/footer/Footer';
import { useServicesStore } from 'store/services.store';
import CerBanner from 'views/home/cerBanner/CerBanner';
import { useTranslation } from 'react-i18next';

const Community: FC = () => {
    const { isCommunityVisible, setIsCommunityVisible } = useServicesStore();
    const desktopWidth = window.innerWidth > 1400;
    const { t } = useTranslation('community');
    return (
        <div className={`${classes.container} ${isCommunityVisible ? classes.openContainer : classes.closedContainer}`}>
            <div className={classes.title}>
                <div onClick={() => setIsCommunityVisible(false)} className={classes.arrow}>
                    {desktopWidth ? <ArrowBackForward /> : <ArrowBackForwardSmall />}
                </div>
                <h3>{t('title')}</h3>
            </div>
            <div className={classes.content}>
                <img alt="Community_Image" src="assets/Community.png" />
                <div className={classes.contentBody}>
                    <p>
                        {t('paragraph_1')} <b>{t('paragraph_1_highlight')}</b>.
                    </p>
                    <p>
                        {t('paragraph_2')} <b>{t('paragraph_2_highlight')}</b> {t('paragraph_2_1')}
                    </p>
                    <div className={classes.applications}>
                        <div className={classes.application}>
                            <div>
                                <DataAnalysis />
                            </div>
                            <div>
                                <span className="sub">{t('monitoring')}</span>
                                <div className={classes.paragraph}>{t('monitoring_paragraph')}</div>
                            </div>
                        </div>
                        <div className={classes.application}>
                            <div>
                                <Split />
                            </div>
                            <div>
                                <span className="sub">{t('optimization')}</span>
                                <div className={classes.paragraph}>{t('optimization_paragraph')}</div>
                            </div>
                        </div>
                        <div className={classes.application}>
                            <div>
                                <Winner />
                            </div>
                            <div>
                                <span className="sub">{t('promotion')}</span>
                                <div className={classes.paragraph}>{t('promotion_paragraph')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CerBanner />
            <Footer />
        </div>
    );
};

export default Community;
