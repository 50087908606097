import { FC } from 'react';
import classes from './CerBanner.module.scss';
import { CircleArrow } from 'components/icons';
import { useTranslation } from 'react-i18next';

const CerBanner: FC = () => {
    const { t } = useTranslation('cerBanner');

    return (
        <div className={classes.container}>
            <h3>{t('title')}</h3>
            <span>
                <b>{t('subtitle')}</b>
            </span>
            <a target="_blank" rel="noreferrer" href={'https://www.comunitaenergetichepnrr.it/chi-siamo/'}>
                <b>{t('learnMore')}</b>
                <CircleArrow />
            </a>
        </div>
    );
};

export default CerBanner;
