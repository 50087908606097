export const ArrowBackForward = () => {
    return (
        <svg width="48" height="48" viewBox="10 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_435_944)">
                <path d="M34 10.7975L31.102 7.9231L15 24L31.1183 40.0769L34 37.2026L20.7635 24L34 10.7975Z" fill="#008FC1" />
            </g>
            <defs>
                <clipPath id="clip0_435_944">
                    <rect width="48" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
