import { LogoSparkflow } from 'components/icons';
import classes from './Homepage.module.scss';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const Homepage: FC = () => {
    const desktopWidth = window.innerWidth > 800;
    const {t} = useTranslation("catchphrase")

    return (
        <div className={classes.container}>
            <img alt="homepage" src={desktopWidth ? 'assets/Homepage.png' : 'assets/Homepage_Mobile.png'} />
            <div className={classes.presentation}>
                <div className={classes.logo}>
                    <LogoSparkflow />
                    <h2>Eagleflow</h2>
                </div>
                <h1>{t("title")}</h1>
            </div>
        </div>
    );
};

export default Homepage;
