export const ArrowBackForwardSmall = () => {
    return (
        <svg width="48" height="48" viewBox="10 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_1759)">
                <path d="M31 14.9667L29.0171 13L18 24L29.0283 35L31 33.0333L21.9434 24L31 14.9667Z" fill="#008FC1" />
            </g>
            <defs>
                <clipPath id="clip0_1_1759">
                    <rect width="48" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
