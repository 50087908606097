export const Electrical = () => {
    return (
        <svg width="48" height="48" viewBox="0 10 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.48">
                <path
                    d="M28.8694 37L19.1162 24.338C19.0498 24.2528 19.01 24.1508 19.0017 24.0442C18.9933 23.9376 19.0167 23.8309 19.0691 23.7369C19.1241 23.641 19.2046 23.5613 19.3022 23.5061C19.3998 23.451 19.5109 23.4224 19.6238 23.4234H28.1646L19.117 11.6729L20.1321 11L29.8838 23.6651C29.9502 23.7502 29.99 23.8522 29.9983 23.9588C30.0067 24.0654 29.9833 24.1721 29.9309 24.2661C29.876 24.3622 29.7955 24.4421 29.6979 24.4974C29.6003 24.5527 29.4892 24.5814 29.3762 24.5804H20.8354L29.8846 36.3271L28.8694 37Z"
                    fill="#008FC1"
                />
            </g>
        </svg>
    );
};
