import { FC } from 'react';
import { ArrowBackForward, ArrowBackForwardSmall } from 'components/icons';
import Footer from 'components/footer/Footer';
import classes from './Offers.module.scss';
import OfferCard from 'components/offerCard/OfferCard';
import { HashLink } from 'react-router-hash-link';
import { useServicesStore } from 'store/services.store';
import { useTranslation } from 'react-i18next';

const Offers: FC = () => {
    const { t } = useTranslation('offers');
    const OFFERS = [
        {
            img: 'assets/offers_1.png',
            title: t('offer_1.title'),
            subtitle: t('offer_1.subtitle'),
            chips: ['Chain2Gate PLUG/DIN', '3 Shelly', 'EagleFlow Appliance', 'Shelly*'],
            paragraph: t('offer_1.paragraph')
        },
        {
            img: 'assets/offers_2.png',
            title: t('offer_2.title'),
            chips: ['Shelly', '2 Chain2Gate PLUG*'],
            subtitle: t('offer_2.subtitle'),
            paragraph: t('offer_2.paragraph')
        },
        {
            img: 'assets/offers_3.png',
            title: t('offer_3.title'),
            chips: ['Shelly', 'Chain2Gate PLUG*'],
            subtitle: t('offer_3.subtitle'),
            paragraph: t('offer_3.paragraph')
        },
        {
            img: 'assets/offers_4.png',
            title: t('offer_4.title'),
            chips: ['2 Shelly', 'Chain2Gate PLUG*'],
            subtitle: t('offer_4.subtitle'),
            paragraph: t('offer_4.paragraph')
        },
        {
            img: 'assets/offers_5.png',
            title: t('offer_5.title'),
            isGreen: true,
            subtitle: t('offer_5.subtitle'),
            paragraph: t('offer_5.paragraph')
        }
    ];
    const { isOffersVisible, setIsOffersVisible, setActiveSection } = useServicesStore();
    const desktopWidth = window.innerWidth > 1400;
    return (
        <div id="offers" className={`${classes.container} ${isOffersVisible ? classes.openContainer : classes.closedContainer}`}>
            <div className={classes.title}>
                <div className={classes.arrow}>
                    <HashLink
                        onClick={() => {
                            setIsOffersVisible(false);
                            setActiveSection('#homePage');
                        }}
                        to="#homePage"
                    >
                        {desktopWidth ? <ArrowBackForward /> : <ArrowBackForwardSmall />}
                    </HashLink>
                </div>
                <h3>{t('title')}</h3>
            </div>
            <div className={classes.subtitle}>
                <p>{t('subtitle_1')}</p>
                <p>{t('subtitle_2')}</p>
            </div>
            <div className={classes.cards}>
                {OFFERS.map(offer => (
                    <OfferCard
                        key={offer.title}
                        img={offer.img}
                        title={offer.title}
                        subtitle={offer.subtitle}
                        paragraph={offer.paragraph}
                        isGreen={offer.isGreen}
                        chips={offer.chips}
                    />
                ))}
            </div>

            <Footer />
        </div>
    );
};

export default Offers;
