import { FC } from 'react';
import classes from './KitCard.module.scss';
import { useTranslation } from 'react-i18next';
import { useServicesStore } from 'store/services.store';

type KitCardProps = FC<{
    img: string;
    title: string;
    subTitle: string;
    paragraph: string;
}>;

const KitCard: KitCardProps = ({ img, paragraph, subTitle, title }) => {
    const { setActiveSection, setIsContactVisible } = useServicesStore();
    const { t } = useTranslation('kit');
    return (
        <div className={classes.container}>
            <div>
                <div className={classes.image}>
                    <img alt={img} src={img} />
                </div>
                <div>
                    <span className="sub">{title}</span>
                    <p>{subTitle}</p>
                </div>
                <div>{paragraph}</div>
            </div>
            <div>
                <a
                    onClick={() => {
                        setIsContactVisible(true);
                        setActiveSection('#contactUs');
                    }}
                    href={'#contactUs'}
                >
                    <b>{t('price')}</b>
                </a>
            </div>
        </div>
    );
};

export default KitCard;
