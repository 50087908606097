import { ArrowBackForward, ArrowBackForwardSmall } from 'components/icons';
import { FC, useState } from 'react';
import { SubmitHandler, UseFormReset, useForm } from 'react-hook-form';
import { HashLink } from 'react-router-hash-link';
import service from 'services/service';
import { useServicesStore } from 'store/services.store';
import classes from './ContactUs.module.scss';
import { useTranslation } from 'react-i18next';

export type Message = {
    message: {
        name: string;
        email: string;
        phone: string;
        text: string;
    };
    isPrivacyChecked?: boolean;
};

export const resetForm = (reset: UseFormReset<Message>, handleSuccessfulMessage: () => void) => {
    reset();
    handleSuccessfulMessage();
};

const ContactUs: FC = () => {
    const { t } = useTranslation('contactUs');
    const { isContactVisible, setIsContactVisible, setActiveSection, setIsPrivacyVisible } = useServicesStore();
    const desktopWidth = window.innerWidth > 1400;
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid }
    } = useForm<Message>({});
    const [isSuccessful, setIsSuccessful] = useState(false);
    const onSubmit: SubmitHandler<Message> = data => {
        service.createApplication(data, reset, handleSuccessfulMessage);
    };
    const handleSuccessfulMessage = () => {
        setIsSuccessful(true);
    };
    return (
        <div
            id="contactUs"
            className={`${classes.container} ${isContactVisible ? classes.openContainer : classes.closedContainer}`}
        >
            <div className={classes.title}>
                <div className={classes.arrow}>
                    <HashLink
                        onClick={() => {
                            setIsContactVisible(false);
                            setActiveSection('#homePage');
                        }}
                        to="#homePage"
                    >
                        {desktopWidth ? <ArrowBackForward /> : <ArrowBackForwardSmall />}
                    </HashLink>
                </div>
                <h3>{t('title')}</h3>
            </div>
            <div className={classes.contentWrapper}>
                <p className={classes.subtitle}>{t('subtitle')}</p>
                <div className={classes.formWrapper}>
                    <img className={classes.formBg} src="assets/form_bg.png" alt="form_bg" />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <p>{t('send')}</p>
                            <input
                                className={`${classes.textBox} ${errors.message?.name ? classes.inputError : ''}`}
                                type="text"
                                placeholder={t('name')}
                                {...register('message.name', { required: true })}
                            />
                            <input
                                className={`${classes.textBox} ${errors.message?.email ? classes.inputError : ''}`}
                                type="email"
                                placeholder={t('email')}
                                {...register('message.email', { required: true })}
                            />
                            <input
                                className={`${classes.textBox} ${errors.message?.phone ? classes.inputError : ''}`}
                                type="text"
                                placeholder={t('phone')}
                                {...register('message.phone', { required: true })}
                            />
                            <textarea
                                className={`${errors.message?.name ? classes.inputError : ''}`}
                                placeholder={t('message')}
                                {...register('message.text', { required: true })}
                            />
                            <div className={`${classes.checkboxWrap} ${errors.isPrivacyChecked ? classes.inputError : ''}`}>
                                <input
                                    type="checkbox"
                                    id="privacyCheckbox"
                                    {...register('isPrivacyChecked', { required: true })}
                                />
                                <label htmlFor="privacyCheckbox">
                                    {t('accept')}&nbsp;
                                    <a onClick={() => setIsPrivacyVisible(true)} href="#privacy">
                                        {t('privacy')}
                                    </a>
                                </label>
                            </div>
                        </div>
                        <p hidden={!isSuccessful} className={classes.successful}>
                            {t('success')}
                        </p>
                        <button disabled={!isValid} type="submit">
                            <b>{t('button')}</b>
                        </button>
                    </form>
                </div>
            </div>
            <footer>
                <div className={classes.footer}>
                    <div>
                        <span>
                            {t('info')}&nbsp;<br></br>
                            <b className="primary300">EagleProjects S.p.A.</b>
                        </span>
                        <span>
                            <a target="_blank" rel="noreferrer" href={'https://www.eagleprojects.it'}>
                                <b>{t('callToAction')}</b>
                            </a>
                        </span>
                    </div>
                    <div>
                        <span>{t('quarter')}</span>
                        <span>
                            <b>Strada San Galigano, 12/A - 06125 PG</b>
                        </span>
                        <span>C.F./P.IVA</span>
                        <span>
                            <b>03489760540</b>
                        </span>
                    </div>
                    <div>
                        <span>Email</span>
                        <span>
                            <b>eagleprojects@pec.it</b>
                        </span>
                        <span>
                            <b>info@eagleprojects.it</b>
                        </span>
                        <span>Telefono</span>
                        <span>
                            <b>075 7750322</b>
                        </span>
                    </div>
                    <img className={classes.footerLogo} src="/assets/logo_eagle_footer.png" alt="logo" />
                </div>
            </footer>
        </div>
    );
};

export default ContactUs;
