import { FC } from 'react';
import classes from './Chip.module.scss';

type ChipProps = FC<{
    title: string;
}>;

const Chip: ChipProps = ({ title }) => {
    return (
        <div className={classes.container}>
            <b>{title}</b>
        </div>
    );
};

export default Chip;
