export const Split = () => {
    return (
        <svg width="48" height="48" viewBox="0 10 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M32.2557 25.9195L31.3793 26.807L32.8877 28.3356H26.4813L22.2029 24.005L26.4796 19.6745H32.8877L31.3793 21.2039L32.2557 22.0881L34.8185 19.4874C34.8761 19.4292 34.9217 19.3601 34.9528 19.284C34.984 19.208 35 19.1264 35 19.0441C35 18.9617 34.984 18.8801 34.9528 18.8041C34.9217 18.728 34.8761 18.6589 34.8185 18.6007L32.2507 16L31.3743 16.8868L32.8819 18.4136H26.2187C26.0544 18.4139 25.8969 18.48 25.7805 18.5973L21.0664 23.3708H13V24.6292H21.0664L25.7805 29.4027C25.8378 29.4613 25.9061 29.5077 25.9814 29.5392C26.0566 29.5708 26.1373 29.5868 26.2187 29.5864H32.8819L31.3743 31.1133L32.2507 32L34.8185 29.3993C34.9342 29.2813 34.9991 29.1218 34.9991 28.9555C34.9991 28.7893 34.9342 28.6298 34.8185 28.5117L32.2557 25.9195Z"
                fill="#008FC1"
            />
        </svg>
    );
};
